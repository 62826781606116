import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';

const ErrorContainer = styled('div')({
    maxWidth: '640px',
    height: '100vh',
});

const ErrorContentContainer = styled(Stack)({
    paddingTop: '33%',
    paddingLeft: '16px',
    paddingRight: '16px',
});

const ErrorTitleText = styled(Typography)({
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '28px',
    color: '#1A1A1A',
    textAlign: 'center',
});

const ErrorContentText = styled(Typography)({
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#515151',
    textAlign: 'center',
});

const MaintenancePage = () => {

    const reloadPage = () => {
        window.location.reload();
    }

    return (
        <ErrorContainer id="maintenance-page">
            <ErrorContentContainer
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >
                <img src={'./maintenance.png'} alt='maintenance' />
                <ErrorTitleText>Halaman Dalam Perbaikan</ErrorTitleText>
                <ErrorContentText >
                    Kesalahan terjadi pada sistem. Mohon menunggu beberapa saat atau klik tombol refresh
                </ErrorContentText>
                <Button
                    variant={'contained'}
                    className={"submitButton"}
                    fullWidth
                    onClick={reloadPage}
                >
                    Muat Ulang
                </Button>
            </ErrorContentContainer>
        </ErrorContainer>
    )
};

export default MaintenancePage;